<script>
	import { page } from '$app/stores';
	import { goto } from '$app/navigation';

	import SignInButton from '$lib/components/Landing/Components/SignInButton.svelte';
	import WanverLogo from '$lib/components/Landing/Assets/WanverLogo.svelte';

	const expandAtY = 400;

	let y = 0;
</script>

<svelte:window bind:scrollY={y} />

<div id="navbar" class={y > expandAtY ? 'sticky' : ''}>
	<div class="flex flex-row justify-between gap-2 py-4 px-4 sm:px-12 {y > expandAtY ? 'bg-indigo-600' : ''} w-full">
		<div class="grid content-center text-white">
			<div class={y > expandAtY ? 'sticky' : 'opacity-0'}>
				<WanverLogo class="w-3/4 sm:w-1/4 md:w-1/4" />
			</div>
		</div>
		<div class="grid content-center">
			<div class="expand-on-small-screens float-right {y > expandAtY ? '' : 'opacity-0'}" style="">
				<SignInButton />
			</div>
		</div>
	</div>
</div>

<style>
	/* Style the navbar */
	:root {
		--transition-duration: 150ms;
	}

	#navbar {
		/* overflow: hidden;
        background-color: #333; */

		width: 80%;
		overflow: hidden;
		position: fixed;
		top: 0;
		z-index: 20;

		padding-right: 2rem;
		padding-right: 2rem;

		left: 0;
		right: 0;
		margin: auto;

		transition: box-shadow var(--transition-duration) ease-out, background-color var(--transition-duration) ease-in,
			width var(--transition-duration) ease-in, opacity var(--transition-duration) ease-in;
	}

	#navbar.sticky {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100vw;
		margin-left: auto;
		margin-right: auto;
		padding: 0px;

		box-shadow: 0 3.4px 6.3px rgba(0, 0, 0, 0.099), 0 27px 50px rgba(0, 0, 0, 0.1);

		transition: box-shadow var(--transition-duration) ease-out, background-color var(--transition-duration) ease-in,
			width var(--transition-duration) ease-in, opacity var(--transition-duration) ease-in;
	}

	@media screen and (max-width: 1100px) {
		#navbar {
			width: 95%;
		}
	}

	@media screen and (max-width: 600px) {
		#navbar {
			grid-template-columns: auto 0 1fr auto;

			min-height: 4rem;
		}

		.expand-on-small-screens {
			padding-left: 16px;
			padding-right: 16px;
			width: 100%;
		}
	}

	@media screen and (max-width: expandAtY5px) {
		#navbar {
			display: none;
			visibility: hidden;
		}
	}
</style>
