<script>
	import SignInButton from '$lib/components/Landing/Components/SignInButton.svelte';
	import LandingNav from '$lib/components/Landing/Components/LandingNav.svelte';
	import ThreeKeyFeatures from '$lib/components/Landing/Components/ThreeKeyFeatures.svelte';
	import WanverLogo from './Assets/WanverLogo.svelte';
	import BackToTop from '$lib/components/Controls/BackToTop.svelte';
	import CollapseCard from '$lib/components/Landing/Components/CollapseCard.svelte';
	import Input from '$lib/components/Controls/Input.svelte';
	import Button from '$lib/components/Controls/Button.svelte';

	let email = '';
	let submitted = undefined;

	const submitEmail = async () => {
		await fetch('/api/landing/submit-email', {
			method: 'POST',
			body: JSON.stringify({ email }),
			headers: { 'Content-Type': 'application/json' },
		});

		email = '';
		submitted = true;
	};
</script>

<LandingNav />

<div style="width: 100vw;" class="">
	<!-- Header -->
	<div class="header bg-indigo-600 text-white">
		<div class="mx-auto flex w-full flex-col gap-10 px-6 pb-32 pt-6 sm:px-8 sm:pb-16 sm:pt-8" style="min-height: 500px;">
			<div class="flex justify-end">
				<SignInButton />
			</div>
			<div class="inner-header flex w-full flex-col content-center gap-5 py-12 px-12 text-center" style="">
				<div class=""><WanverLogo class="mx-auto w-3/4 py-4 sm:w-1/2" style="max-width: 500px;" /></div>
				<div class="mx-auto mt-4 text-3xl font-bold">Get paid to show off products on social media.</div>
				<div class="mx-auto px-4 text-2xl font-medium">
					Post honest reviews of brands like Amazon, GAP and Dove. Get paid when followers buy.
				</div>
			</div>
		</div>
		<!--Waves Container-->
		<div>
			<svg
				class="waves"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				viewBox="0 24 150 28"
				preserveAspectRatio="none"
				shape-rendering="auto"
			>
				<defs>
					<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
				</defs>
				<g class="parallax">
					<use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
					<use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
					<use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
					<use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
				</g>
			</svg>
		</div>
		<!--Waves end-->
	</div>

	<!--Content starts-->
	<div class="pb-128 mb-32 flex w-full flex-col gap-4 px-8 pt-16 md:px-24 lg:px-48">
		<!-- Key "Features" / 3 key facts -->
		<div class="mt-16 text-center">
			<ThreeKeyFeatures />
		</div>

		<!-- More info accordions -->
		<div class="mt-16">
			<div class="flex flex-col gap-4">
				<div>
					<CollapseCard>
						<div slot="header">
							<div class="c-collapse-header text-2xl">Get rid of <span class="t-strikethrough">Link In Bio</span>.</div>
						</div>
						<div slot="body" class="text-md pt-4 text-left">
							<div class="c-collapse-body">
								With Wanver, you no longer have to rely on your audience taking the time to find your ecommerce store and
								affiliate links in your profiles' bio. Wanver allows you to capture those sales directly in your comments, in the
								earliest possible stage. By removing this friction and uncertainy, you can maximize impulrse purchases from your
								customers.
							</div>
						</div>
					</CollapseCard>
				</div>
				<div>
					<CollapseCard>
						<div slot="header">
							<div class="c-collapse-header text-2xl">Collect and act on <span class="t-underline">better data</span>.</div>
						</div>
						<div slot="body" class="text-md pt-4 text-left">
							<div class="c-collapse-body">
								Wanver knows exactly which of your followers are buying, and what. We capture data at every step of the way, so
								you can double down on your most engaged customers and the products they love most.
							</div>
						</div>
					</CollapseCard>
				</div>
				<div>
					<CollapseCard>
						<div slot="header">
							<div class="c-collapse-header text-2xl">Integrates with any <span class="t-underline">selling flow</span>.</div>
						</div>
						<div slot="body" class="text-md pt-4 text-left">
							<div class="c-collapse-body">
								Wanver enables a wide variety of creators to monetize their audience, whether they want to sell via normal posts,
								or livestreams. Wanver was built as a tool to enable side-hustles, our tools can help homemade goods makers, small
								businesses, professional resellers, to affiliate marketers, whether you own the inventory or not.
							</div>
						</div>
					</CollapseCard>
				</div>
			</div>
		</div>

		<!-- More info cards -->
		<div class="mt-16 flex flex-col gap-10">
			<div>
				<div class="flex flex-col gap-4">
					<div class="text-2xl">Sign up with any size following.</div>
					<!-- <div class="text-2xl"> -->
					<!-- Daphne's <span class="t-strikethrough">Lamborghini</span> College Fund -->
					<!-- </div> -->
					<div class="text-md">
						Track your orders, profits and most loyal followers with our dashboard. Reach out personally to thank your most
						engaged customers, double down on products you know resonate with them, and use data to demonstrate your value to
						sponsors.
					</div>
				</div>
			</div>
			<hr />
			<div>
				<div class="flex flex-col gap-4">
					<div class="text-2xl">Sell anything.</div>

					<div class="text-md">
						Sales happen directly in your comment section, and you can show off any product you choose. Whether its selling
						small-batch homemade goods, or running affiliate marketing programs targetting 100s of thousands of users,
					</div>
					<div class="text-md">
						Wanver can help you sell better. Wanver is currently in private beta, and we are looking for creators of all sizes,
						and selling a variety of products to join us.
					</div>
				</div>
			</div>
		</div>

		<!-- Invitation to join beta -->
		<div class="mt-24">
			<div class="flex w-full flex-col gap-4 text-center text-xl">
				<div class="">Sign up to get notified.</div>
				<div class="">
					<Input
						bind:value={email}
						useBaseClass={false}
						placeholder="Enter your email"
						class="mx-auto w-full rounded-3xl border-2 border-indigo-600  bg-white py-2 transition-all duration-150 focus:rounded-3xl focus:ring-2 focus:ring-indigo-600"
						style="max-width: 450px;"
						disabled={submitted}
					/>
				</div>
				<div class="" style="height: 60px">
					<Button
						on:click={submitEmail}
						class="whitespace-nowrapl mx-auto w-full rounded-3xl border-2 border-2 border-indigo-600 bg-indigo-600 px-2 text-center font-medium text-white transition-all duration-200 hover:border-4 hover:border-indigo-600 hover:bg-transparent hover:py-2 hover:font-bold hover:text-indigo-600"
						style="max-width: 450px;"
						disabled={submitted}
					>
						Sign up
					</Button>
				</div>
				{#if submitted}
					<div class="text-indigo-600" style="">Email submitted!</div>
				{/if}
			</div>
		</div>
	</div>

	<!-- Footer -->
	<div class="bg-indigo-600 text-white">
		<div class="mx-auto flex w-full flex-col gap-10 px-16 py-4">
			<div class="flex flex-row justify-center text-xs">© 2022 Wanver. All Rights Reserved.</div>
		</div>
	</div>
</div>

<BackToTop />

<!--Content ends-->
<style>
	.t-strikethrough {
		text-decoration: line-through;
		text-decoration-color: currentcolor;
		text-decoration-thickness: auto;
		text-decoration-color: #4532e6;
		text-decoration-thickness: 3px;
	}
	.t-underline {
		text-decoration: underline;
		text-decoration-color: currentcolor;
		text-decoration-color: #4532e6;
		text-decoration-thickness: 3px;
		text-decoration-skip: none;
		text-decoration-skip-ink: none;
	}
	.waves {
		position: relative;
		width: 100%;
		height: 16vh;
		margin-bottom: -10px; /*Fix for safari gap*/
		min-height: 100px;
		max-height: 150px;
	}

	.parallax > use:nth-child(1) {
		animation-delay: -2s;
		animation-duration: 7s;
	}
	.parallax > use:nth-child(2) {
		animation-delay: -3s;
		animation-duration: 10s;
	}
	.parallax > use:nth-child(3) {
		animation-delay: -4s;
		animation-duration: 13s;
	}
	.parallax > use:nth-child(4) {
		animation-delay: -5s;
		animation-duration: 20s;
	}
	@keyframes move-forever {
		0% {
			transform: translate3d(-90px, 0, 0);
		}
		100% {
			transform: translate3d(85px, 0, 0);
		}
	}
	/*Shrinking for mobile*/
	@media (max-width: 768px) {
		.waves {
			height: 40px;
			min-height: 40px;
		}
	}
</style>
