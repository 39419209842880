<script>
	import BasicCard from '$lib/components/Containers/BasicCard.svelte';
	import SendIcon from '$lib/components/Landing/Icons/SendIcon.svelte';
	import PlusOneCommentIcon from '../Icons/PlusOneCommentIcon.svelte';
	import SocialMediaIcon from '../Icons/SocialMediaIcon.svelte';

	const imgMaxWidth = 300;
</script>

<div class="hidden w-full sm:block">
	<div class="grid w-full grid-cols-3 gap-4 text-black md:gap-8">
		<div class="col-span-1 p-4">
			<SocialMediaIcon class="mx-auto w-full text-indigo-600" style="max-width: {imgMaxWidth}px;" />
		</div>
		<div class="col-span-1 p-4">
			<PlusOneCommentIcon class="mx-auto w-full text-indigo-600" style="max-width: {imgMaxWidth}px;" />
		</div>
		<div class="col-span-1 p-4">
			<SendIcon class="mx-auto w-full text-indigo-600" style="max-width: {imgMaxWidth}px;" />
		</div>
		<div class="col-span-1 text-3xl">
			<div>Sell through social media.</div>
		</div>
		<div class="col-span-1 text-3xl">
			<div>Buy through comments.</div>
		</div>
		<div class="col-span-1 text-3xl">
			<div>Check-out via DM.</div>
		</div>
		<div class="col-span-1 text-xl">
			<div>Post promotional content and reviews.</div>
		</div>
		<div class="col-span-1 text-xl">
			<div>Customers comment +1 on your post to buy the products.</div>
		</div>
		<div class="col-span-1 text-xl">
			<div>Users get sent a link to check-out right in-app.</div>
		</div>
	</div>
</div>

<div class="block w-full sm:hidden">
	<div class="flex w-full flex-col gap-8 text-black">
		<div class="p-4">
			<SocialMediaIcon class="mx-auto mx-auto w-3/4 text-indigo-600" style="max-width: {imgMaxWidth}px;" />
		</div>
		<div class="text-3xl">
			<div>Sell through social media.</div>
		</div>
		<div class="text-xl">
			<div>Post promotional content and reviews.</div>
		</div>
		<div class="p-4">
			<PlusOneCommentIcon class="mx-auto w-full text-indigo-600" style="max-width: {imgMaxWidth}px;" />
		</div>
		<div class="text-3xl">
			<div>Buy through comments.</div>
		</div>
		<div class="text-xl">
			<div>Customers comment +1 on your post to buy the products.</div>
		</div>
		<div class="p-0">
			<SendIcon class="mx-auto text-indigo-600" style="max-width: {imgMaxWidth}px;" />
		</div>
		<div class="text-3xl">
			<div>Check-out via DM.</div>
		</div>
		<div class="text-xl">
			<div>Users get sent a link to check-out right in TikTok.</div>
		</div>
	</div>
</div>
