<script>
	import BasicCard from '$lib/components/Containers/BasicCard.svelte';
	import CollapsibleAreaBase from '$lib/components/Misc/Collapseable/CollapsibleAreaBase.svelte';
	export let open = false;
	const handleCardAreaClick = () => (open = !open);
</script>

<div class="cards cursor-pointer" on:click={handleCardAreaClick}>
	<BasicCard fullWidth={true}>
		<div>
			<CollapsibleAreaBase bind:open>
				<div slot="header" class="flex flex-row justify-between p-0">
					<div>
						<slot name="header" />
					</div>
					<div class="grid content-center">
						<svg
							class="w-5 h-5 {open ? 'rotate-90' : ''} transition delay-50 duration-200"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
								clip-rule="evenodd"
							/>
						</svg>
					</div>
				</div>
				<div slot="body">
					<slot name="body" />
				</div>
			</CollapsibleAreaBase>
		</div>
	</BasicCard>
</div>
